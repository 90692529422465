<template>
    <div>
        <h2>ช่องทางการชำระ > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}ช่องทางการชำระ</h2>
        <v-form v-model="isFormValid">
          <v-card class="mt-4">
            <div class="pa-8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    :items="bankList"
                    label="ธนาคาร"
                    outlined
                    v-model="formData.bank_id"
                    item-text="name"
                    item-value="id"
                    dense
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div class="d-flex align-center">
                        <v-img 
                        v-if="!!item.logo" 
                        :src="item.logo"
                        :max-width="25"
                        class="mr-2"
                        ></v-img> {{ item.name }}               
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อช่องทาง *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.branch"
                    outlined
                    label="สาขา"                               
                    :rules="[validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.accountNo"
                    outlined
                    label="เลขบัญชี *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>

            <v-card-actions>              
              <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="submitForm"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
              >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
              </v-btn>

              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>                
            </v-card-actions>
          </v-card>          
        </v-form>
    </div>
</template>
<script>
import { maxLengthValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPostAsJson } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      bankList: [],
      validators: {
        required,
        maxLengthValidator,
      },
      formData: {
        bank_id: null,
        name: null,
        branch: null,
        accountNo: null,
      },
    }
  },
  async created() {
    this.bankList = await asyncGet('/banks/list')

    const payment_id = this.$route.params.payment_id

    if (payment_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Setting'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/setting/payment/' + payment_id)

      this.formData = {
        bank_id: response.info.bank_id,
        name: response.name,
        branch: response.info.branch,
        accountNo: response.info.accountNo,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const payment_id = this.$route.params.payment_id

        if (payment_id == 'create') await asyncPostAsJson('/setting/payment', this.formData)
        else {
          await asyncPostAsJson('/setting/payment/' + payment_id, { ...this.formData, _method: 'PUT' })
        }

        this.$router.push({ name: 'payment' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>